<template>
  <div>
    <div class="columns">
      <div class="column">
        <h2 class="title is-3">Задачи</h2>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="applications"
          item-value="id"
          item-text="package_name"
          label="Лендинг"
          v-model="application_id"
          @input="filterTasks"
        ></v-select>
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="statuses"
          item-value="value"
          item-text="name"
          label="Статус"
          v-model="status"
          @input="filterTasks"
        ></v-select>
      </div>
      <div class="column">
        <v-select
          outlined
          dense
          hide-details
          clearable
          :items="task_types"
          item-value="value"
          item-text="name"
          label="Тип задачи"
          v-model="type"
          @input="filterTasks"
        ></v-select>
      </div>
    </div>

    <!-- ТАБЛИЦА -->
    <b-table
      :data="tasks.results"
      paginated
      backend-pagination
      :total="tasks.total"
      :per-page="perPage.value"
      @page-change="onPageChange"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
      backend-sorting
      class="users-table"
    >
      <b-table-column
        label="ID"
        width="40"
        numeric
        v-slot="props"
      >
        {{ props.row.id }}
      </b-table-column>

      <b-table-column
        label="Лендинг"
        v-slot="props"
      >
          <span>
            {{ applications.find(o => o.id === props.row.application_id) ? applications.find(o => o.id === props.row.application_id).package_name : 'Не найдено' }}
          </span>
      </b-table-column>

      <b-table-column
        field="phone"
        label="Статус"
        v-slot="props"
      >
          <span>
            {{ statuses.find(o=> o.value === props.row.status).name }}
          </span>
      </b-table-column>

      <b-table-column
        field="second_name"
        label="Тип"
        v-slot="props"
      >
        {{task_types.find(o=> o.value === props.row.type).name}}
      </b-table-column>

      <b-table-column
        field="surname"
        label="Исполнитель"
        v-slot="props"
      >
        {{ profiles.find(o => o.id === props.row.profile_id) ? profiles.find(o => o.id === props.row.profile_id).name : 'Не найдено' }}
      </b-table-column>

      <b-table-column
        field="created_at"
        label="Запланированное время"
        v-slot="props"
      >
        {{ new Date(props.row.planned_at).toLocaleString() }}
      </b-table-column>

      <!-- Пагинация и выбор количества элементов на странице -->
      <template slot="bottom-left">
        <div class="pagination-controls">
          <v-select
            v-model="perPage"
            @change="filterTasks"
            :items="perPageOptions"
            item-text="name"
            item-value="value"
            return-object
            outlined
            hide-details
            dense
            class="per-page-select"
          ></v-select>
        </div>
      </template>

    </b-table>

  </div>
</template>

<script>

export default {
  name: "TasksList",
  data() {
    return {
      application_id: "",
      status: "",
      type: "",
      statuses: [
        { value: "created", name: "Создана" },
        { value: "in_progress", name: "В работе" },
        { value: "done", name: "Выполнена" },
        { value: "canceled", name: "Отменена" },
      ],
      task_types: [
        { value: "create_landing", name: "Создание лендинга" },
        { value: "update_landing", name: "Обновление лендинга" },
        { value: "change_template", name: "Смена шаблона" },
        { value: "delete_landing", name: "Удаление лендинга" },
        { value: "upload", name: "Загрузка"}
      ],

      /* пагинация */
      perPage: {
        value: 20,
      },
      page: 1,
      perPageOptions: [
        { name: "10 на странице", value: 10 },
        { name: "20 на странице", value: 20 },
        { name: "50 на странице", value: 50 },
        { name: "100 на странице", value: 100 },
      ],
    };
  },
  created() {
    this.$store.dispatch('GET_APPLICATIONS', {'all': true});
    this.$store.dispatch("GET_PROFILES", {type: 'SET_PROFILES'});
    this.filterTasks();
  },
  computed: {
    tasks() {
      return this.$store.getters.TASKS;
    },
    applications() {
      return this.$store.getters.APPLICATIONS.results;
    },
    profiles() {
      return this.$store.getters.GET_PROFILES;
    },
  },
  methods: {
    onPageChange(page) {
      this.page = page;
      this.filterTasks();
    },
    onClear() {
      const params = {};
      this.$store.dispatch("GET_TASKS",params);
    },
    filterTasks() {
      const params = {
        application_id: this.application_id,
        status: this.status,
        type: this.type,

        // для пагинации
        page: this.page,
        perPage: this.perPage.value,
      };
      this.$store.dispatch("GET_TASKS", params);
    },
  },
};
</script>

<style>
.draggable-item {
  padding: 10px;
  background-color: #fff;
}

.table_tasks .column{
  padding: 0
}

.table_tasks p{
  padding: 0 15px;
}

.table_tasks .title{
  font-weight: 400 !important;
}

.head_table{
  padding: 0 15px;
  margin-bottom: 0 !important;
}

.head_table p{
  letter-spacing: 15px !important;
  color: #0e4378;
}

.name{
  font-weight: 500 !important;
  font-size: 1.25rem !important;
  letter-spacing: 0.0125em !important;
  font-family: "Roboto", sans-serif !important;
  margin-bottom: 0 !important;
}

</style>

